<template>
  <Summary :validator="validator" :value="value" :payload="payload" :preview="true" class="core-broker-subscription">
    <template #actions>
      <Button label="Forrige" @click="prev" :disabled="isLoading" />
      <Button
        label="Opprett"
        theme="primary"
        :promise="promise"
        :disabled="!validator.passes"
        @click="submit"
        :labels="{
          success: 'Opprettet',
          loading: 'Oppretter...',
          error: 'Noe gikk galt',
        }"
      />

      <Alert
        v-if="message"
        iconPlacement="left"
        :theme="error ? 'error' : 'primary'"
        class="core-broker-subscription__message"
        :key="message"
      >
        <template>
          <div>{{ message }}</div>
        </template>
      </Alert>
    </template>
  </Summary>
</template>

<script>
import { AlertComponent as Alert } from 'vue-elder-alert'
import { ButtonComponent as Button } from 'vue-elder-button'
import Validator from '@/mixins/validator-v2'
import SSE from '@/api/utils/sse'
import Summary from '../subscription/summary.vue'
import fetch from '@/api/fetch'

const ValidatorSettings = {
  labels: {
    'billing.company': 'Bedriftsnavn',
    'billing.email': 'Epost',
    'billing.organizationNumber': 'Org. nr.',
  },
  rules: {
    'billing.company': 'required',
    'billing.email': 'required|email',
    'billing.organizationNumber': 'required',
  },
}

export default {
  inject: ['$token'],

  mixins: [
    Validator({
      data: 'value',
      labels: 'labels',
      rules: 'rules',
    }),
  ],
  props: {
    payload: Object,
    value: Object,
  },

  data() {
    return {
      labels: ValidatorSettings.labels,
      rules: ValidatorSettings.rules,
      isLoading: false,
      message: null,
      promise: null,
      error: null,
    }
  },

  computed: {
    token() {
      return this.$token()
    },
  },

  methods: {
    prev() {
      return this.$emit('navigate', 'products')
    },

    submit() {
      this.message = 'Validerer data'
      this.error = null
      this.isLoading = true

      const { tenant, integrationId } = this.payload
      const url = `https://${tenant}/api/integration/${integrationId}/callbacks/create?token=${this.token}`

      this.promise = new Promise((resolve, reject) => {
        const handleError = err => {
          this.isLoading = false
          reject(err)
        }

        fetch(url, {
          method: 'POST',
          cache: 'no-cache',
          keepalive: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream',
          },
          body: JSON.stringify(this.value),
        })
          .then(res => {
            let reader = SSE(res)

            reader.on('message', data => {
              this.message = data.message
            })

            reader.on('error', data => {
              this.error = true
              this.message = data.message
            })

            reader.on('finished', () => {
              reader.cancel()

              if (this.error) return handleError(new Error('Something went wrong'))

              this.$emit('refetchToken', success => {
                if (!success) return handleError(new Error('Something went wrong'))

                this.$emit('navigate', 'admin')
              })
            })
          })
          .catch(err => {
            handleError(err)
          })
      })
    },
  },
  components: {
    Alert,
    Button,
    Summary,
  },
}
</script>

<style lang="scss">
.core-broker-subscription {
  max-width: 600px;
  margin: 0 auto;

  .card-v2__actions {
    flex-wrap: wrap;
  }

  &__message {
    width: 100%;
  }
}
</style>
