<template>
  <Card
    title="Oppsummering"
    subtitle="Her får du en oppstilling av pris for dine valg."
    variant="prompt"
    class="core-broker-summary"
  >
    <template #default>
      <div class="core-broker-summary__title">
        <div class="flex-row gap-1">
          <h3 class="mb-0">{{ value.name }}</h3>
          <span class="core-broker-summary__badge" v-if="pricing">{{ pricing.label }}</span>
        </div>
        <div class="bold">Pris</div>
      </div>

      <Lines v-for="item in products" :value="item" :title="true" />
      <Lines v-for="item in addons" :value="item" :title="true" />

      <div data-section="total">
        <div>
          <h3>Samlet pris</h3>
          <p>Produkter faktureres pr. kvartal og alle priser er ekskl. mva.</p>
        </div>

        <Lines :value="{ lines: total }" />
      </div>

      <Billing v-model="value.billing" :template="payload.billing" :validator="validator" :preview="preview" />
      <Alert iconPlacement="left" class="mt-2">
        <template>
          <div>Prosjektet vil automatisk fornyes ved utløp med mindre prosjektet blir avsluttet i forkant</div>
        </template>
      </Alert>
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card-v2.vue'
import { InputComponent as Input } from 'vue-elder-input'
import { ButtonComponent as Button } from 'vue-elder-button'
import { AlertComponent as Alert } from 'vue-elder-alert'
import Lines from '@/components/Lines.vue'
import { path } from 'ramda'
import { getTotal } from '@/components/Lines.vue'
import Billing from '@/components/Billing/Component.vue'

export default {
  props: {
    payload: Object,
    value: Object,
    preview: Boolean,
    validator: Object,
  },

  computed: {
    pricing() {
      return this.value.projectType === 'upcoming'
        ? this.payload.upcoming.priceBroker
        : this.payload.project.priceBroker
    },

    products() {
      return [
        {
          title: 'Produkter',
          lines: [
            {
              description: 'Oppsett bruk av mal',
              value: path(['template', 'value'], this.pricing) || 'Pris iht. avtale',
              type: path(['template', 'type'], this.pricing),
            },
            {
              description: this.value.projectType === 'upcoming' ? 'Kommer for salg side' : 'Prosjektside',
              value: path(['project', 'value'], this.pricing) || 'Pris iht. avtale',
              type: path(['project', 'type'], this.pricing),
            },
          ],
        },
      ]
    },

    addons() {
      const getAnglePrice = (v, angles) => {
        if (!Array.isArray(path(['angle'], v))) {
          const anglePrice = path(['angle', 'value'], v) || 'Pris iht. avtale'
          if (typeof anglePrice === 'number') return anglePrice * angles
          return anglePrice
        }

        const price = v.angle.find(
          ({ condition: { lte, gte } }) =>
            (lte === undefined || lte === null || angles >= lte) &&
            (gte === undefined || gte === null || angles <= gte),
        )

        if (!price) return 'Pris iht. avtale'

        if (!price.perAdditional) {
          if (typeof price.value === 'number') return price.value * angles
          return price.value
        }

        if (typeof price.value !== 'number') return price.value

        const limit = Math.max(0, (price.condition.lte || 0) - 1)
        const totalPrice = (angles - limit) * price.value
        return totalPrice
      }

      const getAngleType = (v, angles) => {
        if (!Array.isArray(path(['angle'], v))) {
          return path(['angle', 'type'], v)
        }

        const price = v.angle.find(
          ({ condition: { lte, gte } }) =>
            (lte === undefined || lte === null || angles >= lte) &&
            (gte === undefined || gte === null || angles <= gte),
        )

        return path(['type'], price)
      }

      const lines = [
        {
          description: 'Oppsett av boligvelger',
          type: getAngleType(this.pricing, this.value.flatfinder.angles),
          value: getAnglePrice(this.pricing, this.value.flatfinder.angles),
          condition: this.value.flatfinder.setup,
        },
        {
          description: 'Oppsett av domene',
          type: path(['domain', 'type'], this.pricing),
          value: path(['domain', 'value'], this.pricing) || 'Pris iht. avtale',
          condition: this.value.domain.selected,
        },
        {
          description: 'Oppsett av visualisering',
          type: path(['visualization', 'type'], this.pricing),
          value: path(['visualization', 'value'], this.pricing) || 'Prises etter avtale',
          condition: this.value.visualization.selected,
        },
      ].filter(v => v.condition)

      if (!lines.length) return []

      return [
        {
          title: 'Tilleggsprodukter',
          lines: lines,
        },
      ]
    },

    total() {
      return getTotal([...this.products, ...this.addons].map(({ lines }) => lines).flat())
    },
  },

  components: {
    Input,
    Button,
    Card,
    Alert,
    Lines,
    Billing,
  },
}
</script>

<style lang="scss">
.core-broker-summary {
  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.6rem;
    margin-bottom: var(--spacing-xl);
    border-bottom: 1px solid var(--light-grey);
  }

  &__badge {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--light-grey);
  }

  [data-section='total'] {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    background-color: var(--lightest-grey);

    border-bottom: 2px solid var(--light-grey);
    border-top: 2px solid var(--light-grey);

    margin: var(--spacing-lg) 0;
    padding: var(--card-padding);

    position: relative;
    width: calc(100% + (2 * var(--card-padding)));
    left: calc(var(--card-padding) * -1);

    h3 {
      margin-bottom: 0.25em;
    }

    p {
      margin: 0;
      color: var(--darkest-grey);
      font-size: 0.8rem;
    }

    .summary-lines {
      [data-part='value'] {
        @include typography('h3');
      }
    }
  }
}
</style>
